import {PROVIDER_ERP_ORDER_MAP} from '../Constants';

// const data = {
//   phone: '',
//   name: '',
//   company_code: '',
//   zip: '',
//   invoice_address: '',
//   contact_address: '',
//   delivery_address: '',
//   provider_display: '',
// };

function _formatPhone(phone) {
  return phone.replace(/^\+?886/, '0');
}

const CustomerExcelHeaders = [
  {label: '客戶供應商代號', key: 'phone', default: ''},
  {label: '客戶供應商類別', key: '', default: '2'},
  {label: '客戶供應商簡稱', key: 'name', default: ''},
  {label: '客戶供應商全稱', key: 'name', default: ''},
  {label: '統一編號', key: 'company_code', default: ''},
  {label: '郵遞區號', key: 'zip', default: ''},
  {label: '發票地址', key: 'invoice_address', default: ''},
  {label: '聯絡地址', key: 'contact_address', default: ''},
  {label: '送貨地址', key: 'delivery_address', default: ''},
  {label: '電話(發票地址)', key: 'phone', default: ''},
  {label: '電話(公司地址)', key: 'phone', default: ''},
  {label: '電話(送貨地址)', key: 'phone', default: ''},
  {label: '行動電話', key: 'phone', default: ''},
  {label: '部門 工地編號', key: 'provider_display', default: ''},
  {label: '業務員代號', key: '', default: 'ED'},
  {label: '開立發票方式', key: '', default: '2'},
  {label: '收款方式', key: '', default: ''},
  {label: '請款客戶', key: 'phone', default: ''},
];

function shopee(order, extra) {
  const data = order.raw_data;
  const address = data.recipient_address.full_address;
  return {
    phone: _formatPhone(data.recipient_address.phone),
    name: data.recipient_address.name,
    company_code: '',
    zip: '', // data.recipient_address.zipcode
    invoice_address: address,
    contact_address: address,
    delivery_address: address,
    provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
  };
}

function yahoo(order, extra) {
  const data = order.raw_data;
  return {
    phone: _formatPhone(data.BuyerInfo.Mobile),
    name: data.BuyerInfo.Name,
    company_code: '',
    zip: '',
    invoice_address: data.ReceiverInfo.Address,
    contact_address: data.ReceiverInfo.Address,
    delivery_address: data.ReceiverInfo.Address,
    provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
  };
}

function yahoomall(order, extra) {
  const data = order.raw_data.Response?.Transaction;

  return {
    phone: _formatPhone(data.Receiver._ReceiverMobile),
    name: data.Receiver._ReceiverName,
    company_code: '',
    zip: '', // data.Receiver._ReceiverZipcode,
    invoice_address: data.Receiver._ReceiverAddress,
    contact_address: data.Receiver._ReceiverAddress,
    delivery_address: data.Receiver._ReceiverAddress,
    provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
  };
}

function rakuten(order, extra) {
  const data = order.raw_data;
  const _address = ['postalCode', 'cityName', 'address1']
    .map((k) => data.shipping.deliveryAddress[k])
    .join('');
  return {
    phone: _formatPhone(data.buyerPhoneNumber),
    name: data.buyerName,
    company_code: '',
    zip: '', // data.shipping.deliveryAddress.postalCode,
    invoice_address: _address,
    contact_address: _address,
    delivery_address: _address,
    provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
  };
}

function momomall(order, extra) {
  const data = order.raw_data;
  return {
    // FIXME: 資料很少～～
    phone: '',
    name: data.custName,
    company_code: '',
    zip: '',
    invoice_address: '',
    contact_address: '',
    delivery_address: '',
    provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
  };
}

function momocenter(order, extra) {
  const data = order.raw_data;
  return {
    phone: _formatPhone(data.receiverMobile),
    name: data.custName,
    company_code: '',
    zip: '', // data.postNo,
    invoice_address: data.receiverAddr,
    contact_address: data.receiverAddr,
    delivery_address: data.receiverAddr,
    provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
  };
}

export {
  CustomerExcelHeaders,
  shopee,
  yahoo,
  yahoomall,
  rakuten,
  momomall,
  momocenter,
};
