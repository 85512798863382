import React from 'react';
import styled from 'styled-components';
import {Table, Space, Input, Button} from 'antd';
import {useOutlet, getOutlet, getNewOutlet} from 'reconnect.js';
import {SearchOutlined} from '@ant-design/icons';

// props: page_size, searchFields, defaultQuery?, collection, sorting?
function AntdTableFilterDropdown({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  placeholder = '',
}) {
  return (
    <div style={{padding: 8}}>
      <Input
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        style={{marginBottom: 8, display: 'block'}}
      />
      <Space>
        <Button onClick={clearFilters} size="small" style={{width: 90}}>
          重置
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{width: 90}}>
          搜尋
        </Button>
      </Space>
    </div>
  );
}

export default AntdTableFilterDropdown;
