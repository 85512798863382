import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {Button, message, Input, Table, Select} from 'antd';
import {
  PROVIDER,
  PROVIDER_DISPLAY,
  INTERNAL_ORDER_STATUS,
  INTERNAL_ORDER_STATUS_DISPLAY,
  MAIN_INTERNAL_ORDER_STATUS,
  MAIN_INTERNAL_ORDER_STATUS_DISPLAY,
} from '../../Constants';
import {
  PageTitle,
  SectionTitle,
  FieldRow,
  FieldLabel,
  FieldValue,
  SeperatorLine,
} from '../../Components/Widgets';
import {validDateString} from '../../Utils/Validate';
import * as JStorageActions from '../../Actions/JStorage';
import useAntdTable from '../../Components/AntdTable/useAntdTable';
import moment from 'moment';
import ExcelOrderExportButton from '../../Components/ExcelOrderExportButton';

const LOGISTICS_TYPE = [
  {
    label: '宅配通',
    value: 'ECAN',
  },
  {
    label: '新竹物流',
    value: 'HCT',
  },
  {
    label: '郵局',
    value: 'POST_OFFICE',
  },
  {
    label: '便利帶',
    value: 'MAPLE_EXPRESS',
  },
  {
    label: '嘉里大榮',
    value: 'KTJ',
  },
  {
    label: '黑貓宅急便',
    value: 'EZCAT',
  },
  {
    label: '自行配送',
    value: 'SELF_DELIVERY',
  },
  {
    label: '其他',
    value: 'OTHER',
  },
];
function EditableSelectField(props) {
  const {options, value, onChange} = props;
  return (
    <div>
      <Select placeholder={'物流方式'} value={value} onChange={onChange}>
        {options.map((opt, idx) => (
          <Select.Option key={idx} value={opt.value}>
            {opt.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

function EditableField(props) {
  const {value, onChange} = props;
  return (
    <div>
      <Input value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}

function generateDates(
  dateFrom,
  dateTo,
  isDateToIncluded = true,
  enableDays = [true, true, true, true, true, true, true],
) {
  let diffDayCount =
    moment(dateTo, 'YYYY-MM-DD').diff(moment(dateFrom, 'YYYY-MM-DD'), 'days') +
    (isDateToIncluded ? 1 : 0);

  return [...new Array(diffDayCount)]
    .map((i, idx) =>
      moment(dateFrom, 'YYYY-MM-DD').startOf('day').add(idx, 'days'),
    )
    .filter((md) => enableDays[md.day()])
    .map((md) => md.format('YYYY-MM-DD'));
}

const PAGE_SIZE = 50;
const INTERNAL_ORDER_STATUS_ALL_KEY = 'all';

const TABS = {
  PENDING: MAIN_INTERNAL_ORDER_STATUS.pending,
  DONE: MAIN_INTERNAL_ORDER_STATUS.done,
};

const _INTERNAL_ORDER_STATUS_ARR = Object.keys(INTERNAL_ORDER_STATUS).map(
  (key) => key,
);

function onFieldChange(recordKey, field, setData) {
  return (value) => {
    setData((prev) => ({
      ...prev,
      [recordKey]: {
        ...(prev[recordKey] || {}),
        [field]: value,
      },
    }));
  };
}

function OrderPage(props) {
  const [actions] = useOutlet('actions');
  const [loadingSyncProvider, setLoadingSyncProvider] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(TABS.PENDING);
  const [
    selectedInternalOrderUpdateStatus,
    setSelectedInternalOrderUpdateStatus,
  ] = React.useState(INTERNAL_ORDER_STATUS.pending_pending);
  const [syncProviderDateValue, setSyncProviderDateValue] = React.useState('');
  const [filters, setFilters] = React.useState({
    created_from: '',
    created_to: '',
  });
  const [applyExtraFilters, setApplyExtraFilters] = React.useState([]);
  const fetchData = React.useCallback(async (configs) => {
    let result = await JStorageActions.fetchDocuments(
      'order',
      configs.query,
      configs.sorting,
      configs.paging,
    );
    return result;
  }, []);
  const [editData, setEditData] = React.useState({});
  const _tableColumns = [
    {
      title: '平台',
      dataIndex: 'provider',
      key: 'provider',
      width: 170,
      fixed: 'left',
      render: (_, record) => {
        return PROVIDER_DISPLAY[record.provider];
      },
      filters: [
        {
          text: PROVIDER_DISPLAY.shopee,
          value: PROVIDER.shopee,
        },
        {
          text: PROVIDER_DISPLAY.yahoo,
          value: PROVIDER.yahoo,
        },
        {
          text: PROVIDER_DISPLAY.rakuten,
          value: PROVIDER.rakuten,
        },
        {
          text: PROVIDER_DISPLAY.momocenter,
          value: PROVIDER.momocenter,
        },
        {
          text: PROVIDER_DISPLAY.yahoomall,
          value: PROVIDER.yahoomall,
        },
      ],
    },
    {
      title: '平台訂單編號',
      dataIndex: 'order_id',
      key: 'order_id',
      width: 120,
      fixed: 'left',
      searchable: true,
    },
    {
      title: '顧客姓名',
      dataIndex: 'name',
      key: 'name',
      searchable: true,
      width: 120,
    },
    {
      title: '收件人地址',
      dataIndex: 'recievier_address',
      key: 'recievier_address',
      width: 120,
    },
    {
      title: '手機號碼',
      dataIndex: 'phone',
      key: 'phone',
      searchable: true,
      width: 120,
    },
    {
      title: '訂單總金額',
      dataIndex: 'total',
      key: 'total',
      width: 120,
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: '物流方式',
      dataIndex: 'logistics_type',
      key: 'logistics_type',
      render: (_, record, index) => (
        <EditableSelectField
          options={LOGISTICS_TYPE}
          value={
            editData[record.id]?.['logistics_type'] || record['logistics_type']
          }
          onChange={onFieldChange(record.id, 'logistics_type', setEditData)}
        />
      ),
      filters: LOGISTICS_TYPE.map((t) => ({
        text: t.label,
        value: t.value,
      })),
    },
    {
      title: '訂單狀態',
      dataIndex: 'internal_order_status',
      key: 'internal_order_status',
      width: 120,
      render: (_, record) => {
        return INTERNAL_ORDER_STATUS_DISPLAY[record.internal_order_status];
      },
      filters: _INTERNAL_ORDER_STATUS_ARR
        .filter(
          (k) =>
            activeTab === INTERNAL_ORDER_STATUS_ALL_KEY ||
            k.indexOf(activeTab) === 0,
        )
        .map((k) => ({
          text: INTERNAL_ORDER_STATUS_DISPLAY[k],
          value: INTERNAL_ORDER_STATUS[k],
        })),
    },
    {
      title: '物流單號',
      dataIndex: 'logistics_id',
      key: 'logistics_id',
      render: (_, record, index) => (
        <EditableField
          value={
            editData[record.id]?.['logistics_id'] || record['logistics_id']
          }
          onChange={onFieldChange(record.id, 'logistics_id', setEditData)}
        />
      ),
    },
    {
      title: '訂單編號',
      dataIndex: 'erp_id',
      key: 'erp_id',
      searchable: true,
      width: 120,
    },
    {
      title: '備註',
      dataIndex: 'note',
      key: 'note',
      render: (_, record, index) => (
        <EditableField
          value={editData[record.id]?.['note'] || record['note']}
          onChange={onFieldChange(record.id, 'note', setEditData)}
        />
      ),
    },
  ];

  const {
    loading: fetchingRecords,
    records,
    tableColumns,
    onTableChange,
    antdQueryState,
    jstorgaeQueryState,
    updateList,
  } = useAntdTable({
    tableColumns: _tableColumns,
    fetchRecords: fetchData,
    pageSize: PAGE_SIZE,
    extraFilters: applyExtraFilters,
  });

  const _triggerProviderOrderDumpToJstorage = async function () {
    if (!validDateString(syncProviderDateValue)) {
      message.error('請填入正確格式的平台訂單成立日（YYYY-MM-DD）');
      return;
    }
    try {
      setLoadingSyncProvider(true);
      let resp = await actions.syncProviderOrderToJstorage(
        syncProviderDateValue,
      );
    } catch (err) {
      message.error('無法同步平台訂單資料至系統');
    } finally {
      setLoadingSyncProvider(false);
    }
  };

  const _updateExtraFilters = (activeTab) => {
    if (filters.created_from || filters.created_to) {
      if (
        !validDateString(filters.created_from) ||
        !validDateString(filters.created_to)
      ) {
        message.error('請確認日期格式皆填寫正確！');
        return;
      }

      if (filters.created_from > filters.created_to) {
        message.error('請確認日期區間填寫正確！');
        return;
      }
    }

    let extraFilters =
      activeTab === INTERNAL_ORDER_STATUS_ALL_KEY
        ? []
        : [{internal_order_status: {$regex: `^${activeTab}_`}}];
    if (!filters.created_from && !filters.created_to) {
      setApplyExtraFilters(extraFilters);
      return;
    }

    let datesInRange = generateDates(
      filters.created_from,
      filters.created_to,
    ).map((date) => {
      let [y, m, d] = date.split('-');
      return {y, m, d};
    });

    extraFilters.push(
      // [{"$or":[{"month":2, "day":22, "year": 2022}]}]
      {
        $or: datesInRange.map((d) => ({
          month: Number(d.m),
          day: Number(d.d),
          year: Number(d.y),
        })),
      },
    );
    setApplyExtraFilters(extraFilters);
  };

  const _onActiveTabChange = async (key) => {
    setActiveTab(key);
    _updateExtraFilters(key);
  };

  const _onQueryOrder = async function () {
    _updateExtraFilters(activeTab);
  };

  const _onUpdateOrderStatus = async function () {
    try {
      const bulkWriteOperations = selectedRows.map((r) => {
        return {
          method: 'update_one',
          payload: {
            query: {id: r.id},
            data: {
              internal_order_status: selectedInternalOrderUpdateStatus,
            },
          },
        };
      });

      actions.setLoading(true);
      await JStorageActions.bulkWriteDocuments('order', bulkWriteOperations);
      await updateList(antdQueryState);
      setSelectedRows([]);
    } catch (err) {
      console.log(err);
      message.error('無法更新訂單狀態');
    } finally {
      actions.setLoading(false);
    }
  };

  const _onUpdateEditableOrderFields = async function () {
    try {
      actions.setLoading(true);
      const bulkWriteOperations = Object.keys(editData).reduce(
        (acc, curr, idx) => {
          acc.push({
            method: 'update_one',
            payload: {
              query: {id: curr},
              data: {
                ...editData[curr],
              },
            },
          });
          return acc;
        },
        [],
      );
      if (bulkWriteOperations.length === 0) {
        message.warn('沒有編輯中的訂單資料');
        return;
      }

      let resp = await JStorageActions.bulkWriteDocuments(
        'order',
        bulkWriteOperations,
      );
      if (resp.writeErrors.length !== 0) {
        throw new Error('WRITE_ERROR');
      }
      setEditData({});
      await updateList(antdQueryState);
    } catch (err) {
      message.error('無法更新訂單資料');
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
        <PageTitle>訂單總覽</PageTitle>
      </div>

      <div className="filters" style={{borderBottom: '2px solid #efefef'}}>
        <div className="inputs">
          <div className="item">
            <label>平台訂單成立日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) => setSyncProviderDateValue(e.target.value)}
              value={syncProviderDateValue}
            />
          </div>
        </div>
        <Button
          loading={loadingSyncProvider}
          onClick={_triggerProviderOrderDumpToJstorage}>
          同步平台資料
        </Button>
      </div>

      <div className="filters">
        <div className="inputs">
          <div className="item">
            <label>訂單成立起始日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  created_from: e.target.value,
                })
              }
              value={filters.created_from}
            />
          </div>
          <div className="item">
            <label>訂單成立結束日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  created_to: e.target.value,
                })
              }
              value={filters.created_to}
            />
          </div>
        </div>
        <div className="actions">
          <Button onClick={_onQueryOrder}>搜尋</Button>
        </div>
      </div>

      <div className="tabs">
        {Object.keys(MAIN_INTERNAL_ORDER_STATUS).map((k) => (
          <div
            className={`tab${activeTab === k ? ' active' : ''}`}
            key={k}
            onClick={() => _onActiveTabChange(k)}>
            {MAIN_INTERNAL_ORDER_STATUS_DISPLAY[k]}
          </div>
        ))}
        <div
          className={`tab${
            activeTab === INTERNAL_ORDER_STATUS_ALL_KEY ? ' active' : ''
          }`}
          key={INTERNAL_ORDER_STATUS_ALL_KEY}
          onClick={() => _onActiveTabChange(INTERNAL_ORDER_STATUS_ALL_KEY)}>
          全部
        </div>
      </div>

      <Table
        style={{marginBottom: 150}}
        scroll={{x: 'max-content'}}
        rowKey={(r) => r.id}
        loading={fetchingRecords}
        dataSource={records}
        columns={tableColumns}
        onChange={onTableChange}
        pagination={{
          ...antdQueryState.pagination,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys: selectedRows.map((r) => r.id),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
          },
          getCheckboxProps: (record) => ({
            // disabled: record.name === 'Disabled User',
            // // Column configuration not to be checked
            // name: record.name,
          }),
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {record.items.map((item, idx) => (
                <div key={idx}>
                  {item.sku} | 數量：{item.qty}
                </div>
              ))}
            </>
          ),
          rowExpandable: (record) => Array.isArray(record.items),
        }}
      />

      <div className="actions-panel">
        <div
          style={{
            display: 'flex',
            marginBottom: 10,
            justifyContent: 'space-between',
          }}>
          <Button
            type="primary"
            onClick={_onUpdateEditableOrderFields}
            style={{marginRight: 10}}>
            儲存編輯資料
          </Button>
          <ExcelOrderExportButton filterConfigs={jstorgaeQueryState} />
        </div>

        <Select
          placement={'topRight'}
          value={selectedInternalOrderUpdateStatus}
          onChange={(v) => {
            setSelectedInternalOrderUpdateStatus(v);
          }}>
          {Object.keys(INTERNAL_ORDER_STATUS).map((k) => (
            <Select.Option key={k} value={INTERNAL_ORDER_STATUS[k]}>
              {INTERNAL_ORDER_STATUS_DISPLAY[k]}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="primary"
          onClick={_onUpdateOrderStatus}
          style={{marginLeft: 5}}>
          批次更新訂單狀態
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .tabs {
    display: flex;
    margin-bottom: 20px;

    & > .tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 5px;
      min-width: 80px;
      padding: 10px;
      cursor: pointer;
      transition: border-bottom 0.1s ease-in;
    }

    & > .tab:hover {
      border-bottom: 2px solid var(--theme-color-light);
    }

    & > .tab.active {
      border-bottom: 2px solid var(--theme-color);
    }
  }
  & > .filters {
    padding: 20px 0px;
    & > .note {
      font-size: 14px;
      color: grey;
      text-align: right;
    }
    & > .inputs {
      & > .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & label {
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }
    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  & > .actions-panel {
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #efefef;
    z-index: 5;
  }
`;

export default OrderPage;
