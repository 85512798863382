// const fakeAntdQuery = {
//     pagination: {
//       current: 1,
//       total: 0,
//       pageSize: PAGE_SIZE,
//     },
//     filters: {},
//     sorter: {},
//   };

function antdTableQuery2JstorageQueryConvertor(
  antdQuery,
  defaultQuery,
  {searchFields = [], pageSize = 20} = {},
) {
  let _defaultAntdQuery = {
    pagination: {
      current: 1,
      total: 0,
      pageSize: pageSize,
    },
    filters: {},
    sorter: {},
  };

  let _defaultQuery = defaultQuery
    ? {...defaultQuery}
    : {
        query: {},
        paging: {
          offset: 0,
          limit: pageSize,
        },
        sorting: ['-created'],
      };

  let resultQuery = null;
  let resultAntdQuery = null;
  try {
    if (!antdQuery) {
      throw 'antdQuery is initial state, use _defaultAntdQuery';
    }

    let {pagination, filters, sorter, extra} = antdQuery;
    let _configs = {..._defaultQuery};
    let _queries = [];
    if (!_configs.query) {
      _configs.query = {};
    }

    for (let key of Object.keys(filters || {})) {
      if (filters[key] && filters[key].length > 0) {
        if (searchFields.indexOf(key) !== -1) {
          let _resultTypeQuery = {
            $or: filters[key].map((v) => ({[key]: {$regex: `.*${v}.*`}})),
          };
          _queries.push(_resultTypeQuery);
        } else {
          let _resultTypeQuery = {
            $or: filters[key].map((v) => ({[key]: v})),
          };
          _queries.push(_resultTypeQuery);
        }
      }
    }
    if (_queries.length > 0) {
      if (_configs.query.$and) {
        _configs.query.$and.push(..._queries);
      } else {
        _configs.query.$and = _queries;
      }
    }

    if (extra?.action === 'paginate') {
      _configs.paging.offset = (pagination.current - 1) * pagination.pageSize;
    }

    if (sorter && sorter.field && sorter.order) {
      _configs.sorting = [
        `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`,
      ];
    }

    resultAntdQuery = antdQuery;
    resultQuery = _configs;
  } catch (err) {
    console.log('err', err);
    resultAntdQuery = null;
    resultQuery = null;
  }

  return {
    antdTableQuery: resultAntdQuery || _defaultAntdQuery,
    query: resultQuery || _defaultQuery,
  };
}

export default antdTableQuery2JstorageQueryConvertor;
