import React from 'react';
import AntdTableFilterDropdown from './AntdTableFilterDropdown';
import antdTableQuery2JstorageQueryConvertor from './AntdTableQuery2JstorgaeQueryConvertor';

/**
 * example tabele columns:
 const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '購買人手機',
    dataIndex: 'buyer_phone',
    key: 'buyer_phone',
    searchable: true, // add this
    // filteredValue
    // filterDropdown
  },
  {
    title: '創建時間',
    dataIndex: 'created',
    key: 'created',
    sorter: (a, b) => a.created - b.created,
    render: (_, record, idx) => {
      return format(record.created, 'YYYY-MM-DD HH:mm');
    },
    // filteredValue
  },
  {
  title: '付款狀態',
  dataIndex: 'payment_status',
  key: 'payment_status',
  render: (_, record, idx) => {
    return PAYMENT_STATUS_DISPLAY[record.payment_status];
  },
  filters: [
    {
      text: PAYMENT_STATUS_DISPLAY.success,
      value: PAYMENT_STATUS.success,
    },
    {
      text: PAYMENT_STATUS_DISPLAY.failure,
      value: PAYMENT_STATUS.failure,
    },
    {
      text: PAYMENT_STATUS_DISPLAY.waiting,
      value: PAYMENT_STATUS.waiting,
    },
  ],
  // filteredValue,
}
];
 */

// outsideRangeFilters = [{created: {from: 1636473600000, to: 1638288000000}}]
// extraFilters = // [{"$or":[{"month":2, "day":22, "year": 2022}]}]
function useAntdTable({
  tableColumns,
  fetchRecords,
  pageSize = 5,
  outsideRangeFilters,
  extraFilters,
}) {
  const [records, setRecords] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [jstorgaeQueryState, setJstorgaeQueryState] = React.useState(null);
  const [antdQueryState, setAntdQueryState] = React.useState({
    pagination: {
      current: 1,
      total: 0,
      pageSize,
    },
    filters: {},
    sorter: {},
    extra: {},
  });

  let searchFields = tableColumns
    .filter((c) => !!c.searchable)
    .map((c) => c.dataIndex || c.key);

  const updateList = React.useCallback(
    async (_queryState) => {
      try {
        setLoading(true);
        let {antdTableQuery, query} = antdTableQuery2JstorageQueryConvertor(
          // will return {$and: []} query structure
          _queryState,
          undefined,
          {
            searchFields,
            pageSize,
          },
        );

        // append outside range filters
        if (
          Array.isArray(outsideRangeFilters) &&
          outsideRangeFilters.length > 0
        ) {
          if (Object.keys(query.query).length === 0) {
            query.query['$and'] = [];
          }
          if (query.query['$and'] && Array.isArray(query.query['$and'])) {
            for (let ele of outsideRangeFilters) {
              let key = Object.keys(ele)[0];
              query.query['$and'].push(
                {
                  [key]: {$gte: ele[key].from},
                },
                {
                  [key]: {$lte: ele[key].to},
                },
              );
            }
          } else {
            console.warn(`query strcture incorrect! should be {"$and": [...]}`);
          }
        }

        // append extra filters
        if (Array.isArray(extraFilters) && extraFilters.length > 0) {
          if (Object.keys(query.query).length === 0) {
            query.query['$and'] = [];
          }
          if (query.query['$and'] && Array.isArray(query.query['$and'])) {
            for (let idx = 0; idx < extraFilters.length; idx++) {
              query.query['$and'].push(extraFilters[idx]);
            }
          } else {
            console.warn(`query strcture incorrect! should be {"$and": [...]}`);
          }
        }

        let _resp = await fetchRecords(query);
        setRecords(_resp.results);
        setAntdQueryState({
          ...antdTableQuery,
          pagination: {
            ...antdTableQuery.pagination,
            total: _resp.total,
          },
        });
        setJstorgaeQueryState(query);
      } catch (ex) {
        console.warn(ex);
      } finally {
        setLoading(false);
      }
    },
    [outsideRangeFilters, extraFilters],
  );

  React.useEffect(() => {
    // when outsideRangeFilters or extraFilters or fetchRecords changed, update list as initial antd state
    updateList();
  }, [outsideRangeFilters, extraFilters, fetchRecords]);

  const onTableChange = (
    pagination, // {current: 1, pageSize: 3, total: 100}
    filters,
    sorter,
    extra,
  ) => {
    updateList({
      pagination,
      filters,
      sorter,
      extra,
    });
  };

  const _convertTableColumns = (columns) => {
    return columns.map((c) => {
      let _extraProps = {};
      let key = c.dataIndex || c.key;
      if (!!c.searchable) {
        _extraProps = {
          ..._extraProps,
          filteredValue: antdQueryState.filters?.[key] || null,
          filterDropdown: (params) => (
            <AntdTableFilterDropdown
              placeholder={c.placeholder || c.title}
              {...params}
            />
          ),
        };
      }

      if (c.filters) {
        _extraProps = {
          ..._extraProps,
          filteredValue: antdQueryState.filters?.[key] || null,
        };
      }

      if (c.sorter) {
        _extraProps = {
          ..._extraProps,
          sortOrder:
            antdQueryState.sorter?.columnKey === key &&
            antdQueryState.sorter?.order,
        };
      }

      let _cleanUpAntdColumn = {
        ...c,
        ..._extraProps,
      };

      delete _cleanUpAntdColumn.searchable;

      return _cleanUpAntdColumn;
    });
  };

  return {
    loading,
    records,
    tableColumns: _convertTableColumns(tableColumns),
    onTableChange,
    antdQueryState,
    jstorgaeQueryState,
    updateList,
  };
}

export default useAntdTable;
