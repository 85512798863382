import React from 'react';
import XLSX from 'xlsx';
import {Button, message} from 'antd';
import moment from 'moment';
import {useOutlet} from 'reconnect.js';
import * as JStorageActions from '../Actions/JStorage';
import * as OrderUtil from '../Utils/OrderUtil';
import OrderFakeData from '../OrderFakeData';
import {CustomerExcelHeaders} from '../Utils/OrderUtil.customer';
import {validDateString} from '../Utils/Validate';

const _customerHeaders = CustomerExcelHeaders;
const _headers = [
  {label: '刪除註記', key: '', default: ''},
  {label: '單別(表頭)', key: '', default: 20},
  {label: '訂單單號(表頭)', key: 'erp_id', default: ''},
  {label: '日期(表頭)', key: 'date', default: ''},
  {label: '供應商代號(表頭)', key: 'phone', default: ''},
  {label: '業務員代號(表頭)', key: '', default: 'ED'},
  {label: '部門代號(表頭)', key: 'provider_display', default: ''},
  {label: '稅別(表頭)', key: '', default: 2},
  {label: '庫別代號', key: '', default: 'A'},
  {label: '付款方式代號', key: '', default: 'W1'},
  {label: '送貨方式代號', key: 'delivery_id', default: ''},
  {label: '單價含稅否', key: '', default: '1'},
  {label: '序號', key: 'row_display', default: ''},
  {label: '產品代號', key: 'item_id', default: ''},
  {label: '預交日', key: 'date', default: ''},
  {label: '數量', key: 'item_quantity', default: ''},
  {label: '單價', key: 'price_per_item', default: ''},
  {label: '金額', key: 'item_total', default: ''},
  {label: '外幣單價', key: '', default: ''},
  {label: '外幣金額', key: '', default: ''},
  {label: '備註', key: '', default: ''},
  {label: '訂單批號', key: 'order_id', default: ''},
  {label: '庫別10碼', key: '', default: 'A'},
  {label: '請款客戶', key: 'phone', default: ''},
];

const _remapOrderDataToDisplayString = (order) => {
  return {
    ...order,
  };
};

const _remapCustomerDataToDisplayString = (customer) => {
  return {
    ...customer,
  };
};

function ExcelOrderExportButton(props) {
  const {
    style = {},
    filterConfigs = {},
    disabled = false,
    filenamePostfix = '',
  } = props;
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = React.useState(false);
  const [link, setLink] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [filename, setFilename] = React.useState('');
  const [cusFilename, setCusFilename] = React.useState('');
  const [cusLink, setCusLink] = React.useState(null);
  const [cusData, setCusData] = React.useState([]);
  const [siteConfig, setSiteConfig] = useOutlet('site-config');
  const linkInstance = React.useRef();
  const cusLinkInstance = React.useRef();

  async function _fetchInternalProductData(items) {
    if (items.length === 0) {
      return [];
    }
    try {
      let results = await _multiPageDownload('product', {
        query: {$or: [...items]},
      });
      return results;
    } catch (err) {
      return [];
    }
  }

  async function _onDownload() {
    setLoading(true);
    delete filterConfigs.paging;
    let _data = await _multiPageDownload('order', filterConfigs);

    if (_data.length === 0) {
      message.warn('查無訂單資料');
      setLoading(false);
      return;
    }
    _data = _data.map(OrderUtil.normalizeItemIds);

    let _itemsToFetch = _data
      .map((d) => d.item_ids.map((id) => ({[`${d.provider}_id`]: id})))
      .flat();

    let _resultItems = await _fetchInternalProductData(_itemsToFetch);
    const extra = {products: _resultItems, date: filterConfigs.created_from};
    _data = _data.filter(OrderUtil.isValidOrder);
    let {orders, customers} = OrderUtil.transformOrderListData(_data, extra);

    // setLoading(false)
    // return

    // create order sheet
    let _remapToOrderDisplayData = orders.map(_remapOrderDataToDisplayString);
    let _filename = `yk訂單${
      filterConfigs?.created_from ? `-${filterConfigs?.created_from}` : ''
    }${filenamePostfix ? filenamePostfix : ''}.xlsx`;
    setFilename(_filename);

    const header = _headers.map((h) => h.label);
    const rows = _remapToOrderDisplayData.map((o) => {
      let _result = {};
      for (let h of _headers) {
        _result[h.label] = h.key ? o[h.key] : h.default;
      }
      return _result;
    });

    setData(rows);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {header});
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet');
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
    });
    setLink(
      URL.createObjectURL(
        new Blob([wbout], {type: 'application/octet-stream'}),
      ),
    );

    // create customer sheet
    let _remapToCustomerDisplayData = customers.map(
      _remapCustomerDataToDisplayString,
    );
    let _cusfilename = `yk客供單${
      filterConfigs?.created_from ? `-${filterConfigs?.created_from}` : ''
    }${filenamePostfix ? filenamePostfix : ''}.xlsx`;
    setCusFilename(_cusfilename);

    const cusheader = _customerHeaders.map((h) => h.label);
    const cusRows = _remapToCustomerDisplayData.map((o) => {
      let _result = {};
      for (let h of _customerHeaders) {
        _result[h.label] = h.key ? o[h.key] : h.default;
      }
      return _result;
    });

    setCusData(cusRows);
    const cuswb = XLSX.utils.book_new();
    const cusws = XLSX.utils.json_to_sheet(cusRows, {header: cusheader});
    XLSX.utils.book_append_sheet(cuswb, cusws, 'MySheet');
    const cuswbout = XLSX.write(cuswb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
    });
    setCusLink(
      URL.createObjectURL(
        new Blob([cuswbout], {type: 'application/octet-stream'}),
      ),
    );

    setLoading(false);
  }

  const _multiPageDownload = React.useCallback(async (collection, config) => {
    const PAGE_SIZE = 50;
    let total = 0;
    let _pageIndexs = [];
    let results = [];
    let _config = {
      ...config,
    };
    delete _config.paging;

    try {
      let _firstTry = await JStorageActions.fetchDocuments(
        collection,
        _config.query,
        _config.sorting,
        {
          offset: 0,
          limit: 10,
        },
      );
      total = _firstTry.total;
      _pageIndexs = new Array(Math.ceil(total / PAGE_SIZE))
        .fill('')
        .map((i, idx) => idx);
    } catch (err) {
      console.log('err', err);
      alert('fail to get initial page data!');
      return [];
    }

    for (let idx of _pageIndexs) {
      try {
        let _pageData = await JStorageActions.fetchDocuments(
          collection,
          _config.query,
          _config.sorting,
          {
            offset: idx * PAGE_SIZE,
            limit: PAGE_SIZE,
          },
        );

        results.push(..._pageData.results);
      } catch (err) {
        //
        alert('fail to get page data!');
        console.log(idx, err);
        return [];
      }
    }

    return results;
  }, []);

  React.useEffect(() => {
    if (link && linkInstance && linkInstance.current) {
      let timer = setTimeout(() => {
        linkInstance.current.click();
        setLink(null);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [link, linkInstance]);

  React.useEffect(() => {
    if (cusLink && cusLinkInstance && cusLinkInstance.current) {
      let timer = setTimeout(() => {
        cusLinkInstance.current.click();
        setCusLink(null);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [cusLink, cusLinkInstance]);

  return (
    <div style={style}>
      {
        <Button
          disabled={disabled}
          loading={loading}
          onClick={_onDownload}
          style={{marginRight: 10}}>
          訂單下載
        </Button>
      }
      {link && (
        <a
          href={link}
          ref={linkInstance}
          download={filename || 'yk訂單報表.xlsx'}
          style={{marginRight: 10, display: 'none'}}>
          <Button onClick={() => 0}>訂單下載</Button>
        </a>
      )}

      {cusLink && (
        <a
          href={cusLink}
          ref={cusLinkInstance}
          download={cusFilename || 'yk客供單.xlsx'}
          style={{display: 'none'}}>
          <Button onClick={() => 0}>客供單下載</Button>
        </a>
      )}
    </div>
  );
}

export default ExcelOrderExportButton;
