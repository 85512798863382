const rakuten = [
  {
    _id: {
      $oid: '61936b1bd8f112144e32e4b0',
    },
    order_id: '0039444-211115-1311050821',
    day: 15,
    erp_id: 'A11011150007',
    id: 'c101b3ec-1ab4-40ba-9729-c87f8ccf8168',
    month: 11,
    provider: 'rakuten',
    raw_data: {
      shopKey: {
        marketplaceIdentifier: 'tw',
        shopUrl: 'ya-kuang',
      },
      orderNumber: '0039444-211005-1510318579',
      orderStatus: 'Cancelled',
      orderTotal: 108528,
      itemTotalBeforeDiscount: 108528,
      discountTotal: 0,
      lastModifiedDate: '2021-10-28T06:44:23.000Z',
      orderDate: '2021-10-05T15:10:31.000Z',
      shopperComment:
        '{"shopper_comment":"因目前住家還在裝潢，若需要送貨會電話通知，麻煩請留貴公司電話，要送貨會電話通知，感謝"}',
      campaigns: [
        {
          campaignName: '9/11-10/31全館滿《7000折200》',
          campaignType: 'SHOP_ORDER',
          campaignInfo: {
            discountType: 'AMOUNT_OFF',
            discountValue: 200,
          },
          campaignReferenceId: '530f8bba-6436-47a1-aa95-b1220b8b9d75',
        },
        {
          campaignName: '【3C家電】滿2000折200-1005',
          campaignType: 'MALL_COUPON',
          campaignURL: 'https://www.rakuten.com.tw/event/sale/3c/',
          campaignInfo: {
            discountType: 'AMOUNT_OFF',
            discountValue: 200,
            couponCode: 'VVTY-XRMH-LKM3-2RRC',
          },
          campaignReferenceId: '7a9ac9a5-17cd-4284-8719-c2a4c67f6499',
        },
        {
          campaignName: '9/11-10/31全館滿《7000折200》',
          campaignType: 'SHOP_ORDER',
          campaignInfo: {
            discountType: 'AMOUNT_OFF',
            discountValue: 200,
          },
          campaignReferenceId: 'abce20f0-3819-4b17-bc79-11697ffc0745',
        },
        {
          campaignName: '9/11-10/31全館滿《7000折200》',
          campaignType: 'SHOP_ORDER',
          campaignInfo: {
            discountType: 'AMOUNT_OFF',
            discountValue: 200,
          },
          campaignReferenceId: 'fd78c2bd-a777-4e3f-bb64-307ac8b4bd0b',
        },
      ],
      orderItems: [
        {
          name: {
            en_us:
              '大金 DAIKIN 大關系列冷暖變頻一對一分離式冷氣 RXV28SVLT / FTXV28SVLT',
            zh_TW:
              '大金 DAIKIN 大關系列冷暖變頻一對一分離式冷氣 RXV28SVLT / FTXV28SVLT',
          },
          // baseSku: 'R1808011077',
          baseSku: 'testsku1006',
          orderItemId: '530f8bba-6436-47a1-aa95-b1220b8b9d75',
          unitPrice: '28929',
          quantity: '1',
          discount: '0', // FIXME: what's this?
          itemTotal: '28929',
          // sku: 'R1808011077',
          sku: 'testsku1006',
          originalUnitPrice: '28982',
        },
        {
          name: {
            en_us:
              '大金 DAIKIN 大關系列冷暖變頻一對一分離式冷氣 RXV28SVLT / FTXV28SVLT',
            zh_TW:
              '大金 DAIKIN 大關系列冷暖變頻一對一分離式冷氣 RXV28SVLT / FTXV28SVLT',
          },
          baseSku: 'R1808011077',
          orderItemId: 'abce20f0-3819-4b17-bc79-11697ffc0745',
          unitPrice: '28927',
          quantity: '1',
          discount: '0',
          itemTotal: '28927',
          sku: 'R1808011077',
          originalUnitPrice: '28982',
        },
        {
          name: {
            en_us:
              '大金 DAIKIN 大關系列冷暖變頻一對一分離式冷氣 RXV22SVLT / FTXV22SVLT',
            zh_TW:
              '大金 DAIKIN 大關系列冷暖變頻一對一分離式冷氣 RXV22SVLT / FTXV22SVLT',
          },
          baseSku: 'R1808011076',
          orderItemId: 'fd78c2bd-a777-4e3f-bb64-307ac8b4bd0b',
          unitPrice: '25336',
          quantity: '2',
          discount: '0',
          itemTotal: '50672',
          sku: 'R1808011076',
          originalUnitPrice: '25382',
        },
      ],
      shipping: {
        orderPackageId: '426cac54-9da2-4ab5-a806-93b08dc00f61',
        deliveryAddress: {
          name: '吳 馴棋',
          phoneNumber: '0983688189',
          postalCode: '251',
          countryCode: 'TW',
          stateCode: 'TW-TPQ',
          stateName: '新北市',
          cityCode: 'TW-TPQ-27',
          cityName: '淡水區',
          address1: '新市一路一段345號3樓',
        },
        shippingMethod: '貨運宅配',
        shippingStatus: 'NotShipped',
        shippingFee: '0',
      },
      payment: {
        orderPaymentId: 'e014da32-6fc7-4a53-a4ae-61f0ebff69fc',
        paymentMethod: 'ATM轉帳',
        paymentStatus: 'Cancelled',
        payAmount: '0',
        pointAmount: '0',
        paymentFee: '0',
      },
      buyerName: '吳 馴棋',
      buyerPhoneNumber: '0983688189',
      buyerMemberRank: 'R1',
      checkoutOptionalInfo: [
        {
          name: '發票資訊',
          description:
            'Prompts customers to enter government invoice  information. Enable if you prepare government invoices for customers.',
          messageHeading: '如果您需要統一發票， 請提供以下內容：',
          filledInfo: [
            {
              title: '發票類別',
              inputValue: '二聯',
            },
          ],
        },
      ],
      eInvoice: {
        status: 'Not issued',
        type: 'DUPLICATE',
        forcedAllowance: false,
        carrierType: 'RAKUTEN_MEMBER',
      },
      orderId: '7a9ac9a5-17cd-4284-8719-c2a4c67f6499',
    },
    total: 42700,
    year: 2021,
  },
];

const momocenter = [
  {
    _id: {
      $oid: '61936b1bd8f112144e32e4b1',
    },
    order_id: '21111507231130-001-001-001',
    day: 15,
    erp_id: 'A11011150003',
    id: 'eede4320-d211-4fdc-9b2b-7e7cc27c4c87',
    month: 11,
    provider: 'momocenter',
    raw_data: {
      buyPrice: '26951',
      claimReason: '',
      completeOrderNo: '21111507231130-001-001-001',
      custId: '0847',
      custName: '黃偉博',
      delyGbStr: '',
      delyHopeDate: '2021/11/16',
      describeNote:
        '2022國際牌新春活動\n2021/10/1起至2022/2/26止\n買指定商品贈送\n樂美雅強化餐具 9 件組 SP-2202',
      entpGoodsNo: '0000001', // 'NRC481TVK',
      goodsCode: '8947392',
      goodsDtCode: '002',
      goodsDtInfo: '晶漾黑',
      goodsName:
        '【Panasonic 國際牌】481L 無邊框鋼板系列三門變頻式電冰箱(NR-C481TV)',
      invoiceDate: '2021/11/15',
      invoiceNo: 'UC27410472',
      isNeedRecycleLargeMachine: '否',
      lastPricDate: '2021/11/15 22:22',
      msg: '',
      msgNote: '2011011160156',
      orderGbStr: '一般訂單(約配)',
      packYnStr: '否',
      postNo: '333',
      receiver: '黃偉博',
      receiverAddr: '桃園市 龜山區 文德二路116巷11弄8號5樓',
      receiverMobile: '0928627677',
      receiverSeq: '0000000002',
      receiverTel: '',
      remark5VStr: '已電聯，尚未確認配送日期',
      salePrice: '28490',
      scmDelyDate: '',
      slipNo: '',
      syslast: '1',
      itemNo: '1',
      isRedStr: '正常',
    },
    total: 28490,
    year: 2021,
  },
];

const yahoo = [
  {
    _id: {
      $oid: '61937052d8f112144e33a6d7',
    },
    order_id: 'RM2111100006272',
    day: 10,
    erp_id: 'A11011100005',
    id: '1ad06025-2d0c-4574-97fd-89b243e96065',
    month: 11,
    provider: 'yahoo',
    raw_data: {
      OrderInfo: {
        OrderCode: 'RM2111100006272',
        TransferDate: '2021-11-10T06:49:06',
        ExpectedShippingDate: '2021-11-11T23:59:00',
        LastShippingDate: '2021-11-11T23:59:00',
        Note: '{[屬性:絲紋黑]}',
      },
      ReceiverInfo: {
        Name: '張志龍',
        Address: '(832)高雄市林園區頂瑤溝119巷71號',
        Phone: '09-76288561',
        Mobile: '0976288561',
      },
      BuyerInfo: {
        Name: '吳亦玲',
        Mobile: '0976288561',
      },
      Products: [
        {
          Id: 10000099,
          Name:
            'Panasonic國際牌 610公升 無邊框鋼板系列變頻三門電冰箱 NR-C611XV-絲紋黑',
          Attribute: '絲紋黑',
          SupplierNo: 'NRC611XVV',
          Type: '客約送貨日',
          Qty: 1,
          Cost: 36509, // 商品成本價
          Subtotal: 36509, // 成本價小計
          ProductPrice: 38900, // 商品售價
        },
        {
          Id: 28540288,
          Name: '樂美雅強化餐具組  SP-2202',
          Attribute: '',
          SupplierNo: 'SP2202',
          Type: '客約送貨日',
          Qty: 1,
          Cost: 0,
          Subtotal: 0,
          ProductPrice: 0,
        },
      ],
    },
    total: 38900,
    year: 2021,
  },
];

const yahoomall = [
  {
    _id: {
      $oid: '61937052d8f112144e33a6d6',
    },
    order_id: 'YM2111105001515',
    day: 10,
    erp_id: 'A11011100004',
    id: 'bfbeefa3-e468-4831-b5b9-f05c3ae2db1b',
    month: 11,
    provider: 'yahoomall',
    raw_data: {
      Response: {
        '@Status': 'ok',
        Transaction: {
          '@Id': '215784288',
          Receiver: {
            _ReceiverName: '黃淑宜',
            _ReceiverPhone: '0932-016569#',
            _ReceiverMobile: '0932016569',
            _ReceiverZipcode: '201',
            _ReceiverAddress: '基隆市201信義區正信路227巷23弄21號6樓',
          },
          SuccessList: {
            '@Count': '1',
            OrderList: {
              Order: {
                '@Id': 'YM2111105001515',
                OrderProductList: {
                  Product: {
                    // '@Id': 'p0732218019324_1',
                    '@Id': 'p073240849215',
                    SaleType: 'Normal',
                    ProductType: 'Main',
                    Amount: '1',
                    OriginalPrice: '1490',
                    ListPrice: '1490', // FIXME: (ListPrice) or OriginalPrice?
                    ProductCostAmount: '1385',
                    PromotionCampaignId: '',
                    PromotionReduction: '0',
                    PromotionCode: '',
                    UsedPoint: '0',
                    BasicPointDiscount: '0',
                    ProductDiscountDetail: '0',
                    Subtotal: '1490',
                    TaxType: 'Taxable',
                    _CustomizedProductId: 'NSWXF10',
                    _ProductName: '象印 6人份黑金剛厚釜微電腦電子鍋 NS-WXF10',
                    _Spec: '-',
                  },
                },
                TransferDate: '2021/11/10 01:55',
                LastShippingDate: '2021/11/24',
                OrderShippingDate: '2021/11/11 13:29',
                OrderCloseDate: '2021/11/11',
                BuyerConfirmDate: '1900/01/01 00:00',
                EntryAccountDate: '1900/01/01',
                PickingDate: '1900/01/01',
                OrderPackageDate: '1900/01/01',
                InvoiceNo: 'UJ48564638',
                InvoiveDate: '2021/11/11',
                LastDeliveryDate: '1900/01/01',
                OrderStatus: 'SHIPPED',
                _DeliverType: '台灣宅配通(9)',
                _OrderNote: '0932016569',
                _OrderStatusDesc: '完成出貨',
                _OrderShippingId: '178058854562',
              },
            },
          },
          FailList: {
            '@Count': '0',
            OrderList: null,
          },
        },
        _Message: '',
      },
    },
    total: 1490,
    year: 2021,
  },
  {
    _id: {
      $oid: '61937052d8f112144e33a6d6',
    },
    order_id: 'YM2111105001515',
    day: 10,
    erp_id: 'A11011100004',
    id: 'bfbeefa3-e468-4831-b5b9-f05c3ae2db1b',
    month: 11,
    provider: 'yahoomall',
    raw_data: {
      Response: {
        '@Status': 'ok',
        Transaction: {
          '@Id': '215784288',
          Receiver: {
            _ReceiverName: '黃淑宜',
            _ReceiverPhone: '0932-016569#',
            _ReceiverMobile: '0932016569',
            _ReceiverZipcode: '201',
            _ReceiverAddress: '基隆市201信義區正信路227巷23弄21號6樓',
          },
          SuccessList: {
            '@Count': '1',
            OrderList: {
              Order: {
                '@Id': 'YM2111105001515',
                OrderProductList: {
                  Product: {
                    // '@Id': 'p0732218019324_1',
                    '@Id': 'p0732211793304',
                    SaleType: 'Normal',
                    ProductType: 'Main',
                    Amount: '1',
                    OriginalPrice: '1490',
                    ListPrice: '1490', // FIXME: (ListPrice) or OriginalPrice?
                    ProductCostAmount: '1385',
                    PromotionCampaignId: '',
                    PromotionReduction: '0',
                    PromotionCode: '',
                    UsedPoint: '0',
                    BasicPointDiscount: '0',
                    ProductDiscountDetail: '0',
                    Subtotal: '1490',
                    TaxType: 'Taxable',
                    _CustomizedProductId: 'NSWXF10',
                    _ProductName: '象印 6人份黑金剛厚釜微電腦電子鍋 NS-WXF10',
                    _Spec: '-',
                  },
                },
                TransferDate: '2021/11/10 01:55',
                LastShippingDate: '2021/11/24',
                OrderShippingDate: '2021/11/11 13:29',
                OrderCloseDate: '2021/11/11',
                BuyerConfirmDate: '1900/01/01 00:00',
                EntryAccountDate: '1900/01/01',
                PickingDate: '1900/01/01',
                OrderPackageDate: '1900/01/01',
                InvoiceNo: 'UJ48564638',
                InvoiveDate: '2021/11/11',
                LastDeliveryDate: '1900/01/01',
                OrderStatus: 'SHIPPED',
                _DeliverType: '台灣宅配通(9)',
                _OrderNote: '0932016569',
                _OrderStatusDesc: '完成出貨',
                _OrderShippingId: '178058854562',
              },
            },
          },
          FailList: {
            '@Count': '0',
            OrderList: null,
          },
        },
        _Message: '',
      },
    },
    total: 1490,
    year: 2021,
  },
];

const momomall = [
  {
    _id: {$oid: '61938028d8f112144e3ae9cb'},
    order_id: '30211004156039-001',
    day: 4,
    erp_id: 'A11010040001',
    id: 'f0d04077-9614-4159-a9d4-cf973fde4b0e',
    month: 10,
    provider: 'momomall',
    raw_data: {
      codeName: '信用卡',
      custId: '3405',
      custName: '莊乙庭',
      entpGoodsNo: 'S3333',
      fullOrderNo: '30211004156039-001',
      goodsCode: '1008290018803',
      goodsInfo1: '單一規格',
      goodsInfo2: '',
      goodsName: '飛利浦 Philips 乾濕兩用電鬍刀 S3333',
      goodsTypes: '一般',
      goodsdtCode: '001',
      goodsdtInfo: '單一規格',
      invoiceDate: '20211005',
      invoiceNo: 'SH31349991',
      outDelyGb: '宅配通',
      procDate: '2021/10/05 16:46',
      processStatus: '配送結束',
      promoId: '',
      promoName: '',
      returnName: '',
      sendTypes: '',
      seqNo: '2',
      slipNo: '178053807094',
      storeOrderStatus: '',
      sysLast: '1',
      sysLastAmt: '1490', // FIXME: 是這個欄位嗎 這是總價？ quantity 都寫 1？
    },
    total: 1490,
    year: 2021,
    created: 1637219979486,
  },
  {
    _id: {$oid: '61938028d8f112144e3ae9cc'},
    order_id: '30211004155165-001',
    day: 4,
    erp_id: 'A11010040002',
    id: '374f74b1-6e9d-4ba0-97c0-97e6608b2d04',
    month: 10,
    provider: 'momomall',
    raw_data: {
      codeName: '信用卡',
      custId: '1341',
      custName: '孫綵瀅',
      entpGoodsNo: 'GNL297SV',
      fullOrderNo: '30211004155165-001',
      goodsCode: '1008290017929', // '1008290014900',
      goodsInfo1: '單一規格',
      goodsInfo2: '',
      goodsName: 'LG 208公升雙門變頻冰箱 GN-L297SV',
      goodsTypes: '一般',
      goodsdtCode: '001',
      goodsdtInfo: '單一規格',
      invoiceDate: '20211004',
      invoiceNo: 'SH31347585',
      outDelyGb: '其他',
      procDate: '2021/10/04 16:40',
      processStatus: '配送結束',
      promoId: '',
      promoName: '',
      returnName: '',
      sendTypes: '',
      seqNo: '1',
      slipNo: '2011010040185',
      storeOrderStatus: '',
      sysLast: '1',
      sysLastAmt: '14841',
    },
    total: 14841,
    year: 2021,
    created: 1637219979486,
  },
];

const shopee = [
  {
    _id: {
      $oid: '619642bbd8f112144e779dcb',
    },
    order_id: '211118U7QSX664',
    created: 1637287342199,
    day: 18,
    erp_id: 'A11011180015',
    id: '978b1471-24f5-4633-8f04-ddeec3b1fda8',
    month: 11,
    provider: 'shopee',
    raw_data: {
      buyer_user_id: 32992986,
      buyer_username: 'ask6387',
      cod: false,
      create_time: 1637236829,
      currency: 'TWD',
      days_to_ship: 3,
      goods_to_declare: false,
      item_list: [
        {
          item_id: 9886202859,
          item_name:
            'Philips 飛利浦 三刀頭全自動水洗刮鬍刀 S3333 保固兩年 刷卡分期含發票 免運費【雅光電器商城】',
          item_sku: '',
          model_id: 0,
          model_name: '',
          model_sku: '',
          model_quantity_purchased: 1,
          model_original_price: 1490,
          model_discounted_price: 1490,
          wholesale: false,
          weight: 0,
          add_on_deal: false,
          main_item: false,
          add_on_deal_id: 0,
          promotion_type: '',
          promotion_id: 0,
          order_item_id: 9886202859,
          promotion_group_id: 0,
          image_info: {
            image_url:
              'https://cf.shopee.tw/file/208b82ca789820d2aa08e54a3882d24b_tn',
          },
        },
      ],
      message_to_seller: '',
      order_sn: '211118U7QSX664',
      order_status: 'READY_TO_SHIP',
      package_list: [
        {
          package_number: 'OFG90936028352954',
          logistics_status: 'LOGISTICS_READY',
          shipping_carrier: '賣家宅配',
          item_list: [
            {
              item_id: 9886202859,
              model_id: 0,
            },
          ],
        },
      ],
      payment_method: 'Credit/Debit Card',
      recipient_address: {
        name: '聶磊',
        phone: '886952866387',
        town: '',
        district: '',
        city: '新店區',
        state: '新北市',
        region: 'TW',
        zipcode: '231',
        full_address: '231新北市新店區吉祥街3巷3號',
      },
      region: 'TW',
      reverse_shipping_fee: 0,
      ship_by_date: 1637668929,
      shipping_carrier: '賣家宅配',
      split_up: false,
      total_amount: 1407,
      update_time: 1637236929,
    },
    total: 1407,
    year: 2021,
  },
  {
    _id: {
      $oid: '619642bbd8f112144e779dcc',
    },
    order_id: '211118U6UF8013',
    created: 1637287342199,
    day: 18,
    erp_id: 'A11011180016',
    id: '400a18f1-5af6-474b-873d-82584d6ca2b1',
    month: 11,
    provider: 'shopee',
    raw_data: {
      buyer_user_id: 366075908,
      buyer_username: 'zxc806550',
      cod: false,
      create_time: 1637235877,
      currency: 'TWD',
      days_to_ship: 3,
      goods_to_declare: false,
      item_list: [
        {
          item_id: 1656024,
          item_name:
            'HERAN 禾聯 碳素定時電暖器 HHF-80CK020 刷卡分期0利率 免運費 公司貨 保固一年【雅光電器商城】',
          item_sku: '',
          model_id: 0,
          model_name: '',
          model_sku: '',
          model_quantity_purchased: 1,
          model_original_price: 1990,
          model_discounted_price: 1990,
          wholesale: false,
          weight: 0,
          add_on_deal: false,
          main_item: false,
          add_on_deal_id: 0,
          promotion_type: '',
          promotion_id: 0,
          order_item_id: 9196480518,
          promotion_group_id: 0,
          image_info: {
            image_url:
              'https://cf.shopee.tw/file/6faeba839c903a9a28d0585007aea1a9_tn',
          },
        },
      ],
      message_to_seller: '',
      order_sn: '211118U6UF8013',
      order_status: 'READY_TO_SHIP',
      package_list: [
        {
          package_number: 'OFG90935077469989',
          logistics_status: 'LOGISTICS_READY',
          shipping_carrier: '賣家宅配',
          item_list: [
            {
              item_id: 9196480518,
              model_id: 0,
            },
          ],
        },
      ],
      payment_method: 'Bank Transfer',
      recipient_address: {
        name: '亮晶晶洗車場',
        phone: '886958341665',
        town: '',
        district: '',
        city: '樹林區',
        state: '新北市',
        region: 'TW',
        zipcode: '238',
        full_address: '238新北市樹林區新北市樹林區八德街94號',
      },
      region: 'TW',
      reverse_shipping_fee: 0,
      ship_by_date: 1637667965,
      shipping_carrier: '賣家宅配',
      split_up: false,
      total_amount: 1990,
      update_time: 1637235965,
    },
    total: 1990,
    year: 2021,
  },
];

const data = {
  total: 6,
  results: [
    ...rakuten,
    ...momocenter,
    ...yahoo,
    ...yahoomall,
    ...momomall,
    ...shopee,
  ],
};

export default data;
