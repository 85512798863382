import {PROVIDER, PROVIDER_ERP_ORDER_MAP} from '../Constants';
import * as Customer from './OrderUtil.customer';

const DEFAULT_SHIPPING_ITEM_ID = 'Z1';
function _getShippingCost(internalProduct, key) {
  try {
    return internalProduct[key].shipping_cost;
  } catch (err) {
    return 0;
  }
}

function _getDeliveryId(internalProduct, key) {
  try {
    return internalProduct[key].delivery_id;
  } catch (err) {
    return '';
  }
}

// const data = {
//     erp_id: "",
//     phone: "",
//     date: "2021-11-11",
//     provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
//     row_display: "001",
//     item_id: "產品代號 || 運費 Z1",
//     item_quantity: "運費填 1",
//     price_per_item: "(訂單總額 - 平台設定商品運費)/數量",
//     item_total: "(訂單總額 - 平台設定商品運費) 每個商品會拆成兩 row (一個運費一個商品價",
//     order_id: "",
// };

function shopee(order, extra) {
  const {products = [], date = ''} = extra;
  const key = 'shopee';

  let orderResult = [];
  for (let itemId of order.item_ids) {
    let found = products.find((p) => p[`${key}_id`] === itemId);
    let _item =
      order.raw_data.item_list.find((item) => item.item_id === itemId) || null;
    _item &&
      orderResult.push(
        _transform(order, {..._item, item_id: itemId}, found || null),
      );
  }

  function _transform(order, item, internalProduct) {
    const itemPrice = item.model_discounted_price;
    const quantity = item.model_quantity_purchased;
    const itemTotal = itemPrice * quantity;
    const itemShippingCost = _getShippingCost(internalProduct, key);
    const itemDeliveryId = _getDeliveryId(internalProduct, key);
    const phone = (order.raw_data.recipient_address?.phone || '').replace(
      /^[+]{0,1}886/,
      '0',
    ); // shopee 格式是 886 開頭
    const _baseData = {
      ...order,
      delivery_id: itemDeliveryId,
      erp_id: order.erp_id,
      order_id: order.order_id,
      phone: phone,
      date: date,
      provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
    };

    return [
      {
        ..._baseData,
        item_id: internalProduct?.id || `${order.provider}::${item.item_id}`,
        item_quantity: quantity,
        price_per_item: itemTotal > 0 ? itemPrice - itemShippingCost : 0,
        item_total: itemTotal > 0 ? itemTotal - itemShippingCost * quantity : 0,
      },
      {
        ..._baseData,
        item_id: internalProduct?.delivery_type || DEFAULT_SHIPPING_ITEM_ID,
        item_quantity: quantity,
        price_per_item: itemShippingCost,
        item_total: itemShippingCost * quantity,
      },
    ];
  }

  return orderResult.flat();
}

function momomall(order, extra) {
  const {products = [], date = ''} = extra;
  const key = 'momomall';

  let orderResult = [];
  for (let itemId of order.item_ids) {
    let found = products.find((p) => p[`${key}_id`] === itemId);
    let _item = order.raw_data || null;
    _item &&
      orderResult.push(
        _transform(order, {..._item, item_id: itemId}, found || null),
      );
  }

  function _transform(order, item, internalProduct) {
    const itemPrice = Number(item.sysLastAmt);
    const quantity = 1; // FIXME: unknown
    const itemTotal = Number(item.sysLastAmt);
    const itemShippingCost = _getShippingCost(internalProduct, key);
    const itemDeliveryId = _getDeliveryId(internalProduct, key);
    const phone = ''; // FIXME: 沒有 phone
    const _baseData = {
      ...order,
      delivery_id: itemDeliveryId,
      erp_id: order.erp_id,
      order_id: order.order_id,
      phone: phone,
      date: date,
      provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
    };

    return [
      {
        ..._baseData,
        item_id: internalProduct?.id || `${order.provider}::${item.item_id}`,
        item_quantity: quantity,
        price_per_item: itemTotal > 0 ? itemPrice - itemShippingCost : 0,
        item_total: itemTotal > 0 ? itemTotal - itemShippingCost * quantity : 0,
      },
      {
        ..._baseData,
        item_id: internalProduct?.delivery_type || DEFAULT_SHIPPING_ITEM_ID,
        item_quantity: quantity,
        price_per_item: itemShippingCost,
        item_total: itemShippingCost * quantity,
      },
    ];
  }

  return orderResult.flat();
}

function yahoomall(order, extra) {
  const {products = [], date = ''} = extra;
  const key = 'yahoomall';

  let orderResult = [];
  for (let itemId of order.item_ids) {
    let found = products.find((p) => p[`${key}_id`] === itemId);
    let _item =
      order.raw_data.Response.Transaction?.SuccessList?.OrderList?.Order
        ?.OrderProductList?.Product || null;
    _item &&
      orderResult.push(
        _transform(order, {..._item, item_id: itemId}, found || null),
      );
  }

  function _transform(order, item, internalProduct) {
    const itemPrice = Number(item.ListPrice);
    const quantity = Number(item.Amount);
    const itemTotal = Number(item.Subtotal);
    const itemShippingCost = _getShippingCost(internalProduct, key);
    const itemDeliveryId = _getDeliveryId(internalProduct, key);
    const phone = (
      order.raw_data.Response.Transaction?.Receiver?._ReceiverMobile || ''
    ).replace(/^[+]{0,1}886/, '0');
    const _baseData = {
      ...order,
      delivery_id: itemDeliveryId,
      erp_id: order.erp_id,
      order_id: order.order_id,
      phone: phone,
      date: date,
      provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
    };

    return [
      {
        ..._baseData,
        item_id: internalProduct?.id || `${order.provider}::${item.item_id}`,
        item_quantity: quantity,
        price_per_item: itemTotal > 0 ? itemPrice - itemShippingCost : 0,
        item_total: itemTotal > 0 ? itemTotal - itemShippingCost * quantity : 0,
      },
      {
        ..._baseData,
        item_id: internalProduct?.delivery_type || DEFAULT_SHIPPING_ITEM_ID,
        item_quantity: quantity,
        price_per_item: itemShippingCost,
        item_total: itemShippingCost * quantity,
      },
    ];
  }
  return orderResult.flat();
}

function yahoo(order, extra) {
  const {products = [], date = ''} = extra;
  const key = 'yahoo';

  let orderResult = [];
  for (let itemId of order.item_ids) {
    let found = products.find((p) => p[`${key}_id`] === itemId);
    let _item = order.raw_data.Products.find((it) => it.Id === itemId);
    orderResult.push(
      _transform(order, {..._item, item_id: itemId}, found || null),
    );
  }

  function _transform(order, item, internalProduct) {
    const itemPrice = Number(item.ProductPrice);
    const quantity = Number(item.Qty);
    const itemTotal = Number(item.ProductPrice) * quantity;
    const itemShippingCost = _getShippingCost(internalProduct, key);
    const itemDeliveryId = _getDeliveryId(internalProduct, key);
    const phone = (order.raw_data.BuyerInfo?.Mobile || '').replace(
      /^[+]{0,1}886/,
      '0',
    );
    const _baseData = {
      ...order,
      delivery_id: itemDeliveryId,
      erp_id: order.erp_id,
      order_id: order.order_id,
      phone: phone,
      date: date,
      provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
    };

    return [
      {
        ..._baseData,
        item_id: internalProduct?.id || `${order.provider}::${item.item_id}`,
        item_quantity: quantity,
        price_per_item: itemTotal > 0 ? itemPrice - itemShippingCost : 0,
        item_total: itemTotal > 0 ? itemTotal - itemShippingCost * quantity : 0,
      },
      {
        ..._baseData,
        item_id: internalProduct?.delivery_type || DEFAULT_SHIPPING_ITEM_ID,
        item_quantity: quantity,
        price_per_item: itemShippingCost,
        item_total: itemShippingCost * quantity,
      },
    ];
  }

  return orderResult.flat();
}

function rakuten(order, extra) {
  const {products = [], date = ''} = extra;
  const key = 'rakuten';

  let orderResult = [];
  for (let itemId of order.item_ids) {
    let found = products.find((p) => p[`${key}_id`] === itemId);
    let _item = order.raw_data.orderItems.find((it) => it.baseSku === itemId);
    orderResult.push(
      _transform(order, {..._item, item_id: itemId}, found || null),
    );
  }

  function _transform(order, item, internalProduct) {
    const itemPrice = Number(item.unitPrice);
    const itemTotal = Number(item.itemTotal);
    const quantity = Number(item.quantity);
    const itemShippingCost = _getShippingCost(internalProduct, key);
    const itemDeliveryId = _getDeliveryId(internalProduct, key);
    const phone = (
      order.raw_data.shipping?.deliveryAddress?.phoneNumber || ''
    ).replace(/^[+]{0,1}886/, '0');
    const _baseData = {
      ...order,
      delivery_id: itemDeliveryId,
      erp_id: order.erp_id,
      order_id: order.order_id,
      phone: phone,
      date: date,
      provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
    };

    return [
      {
        ..._baseData,
        item_id: internalProduct?.id || `${order.provider}::${item.item_id}`,
        item_quantity: quantity,
        price_per_item: itemTotal > 0 ? itemPrice - itemShippingCost : 0,
        item_total: itemTotal > 0 ? itemTotal - itemShippingCost * quantity : 0,
      },
      {
        ..._baseData,
        item_id: internalProduct?.delivery_type || DEFAULT_SHIPPING_ITEM_ID,
        item_quantity: quantity,
        price_per_item: itemShippingCost,
        item_total: itemShippingCost * quantity,
      },
    ];
  }

  return orderResult.flat();
}

function momocenter(order, extra) {
  const {products = [], date = ''} = extra;
  const key = 'momocenter';

  let orderResult = [];
  for (let itemId of order.item_ids) {
    let found = products.find((p) => p[`${key}_id`] === itemId);
    orderResult.push(_transform(order, {item_id: itemId}, found || null));
  }

  function _transform(order, item, internalProduct) {
    const itemPrice = Number(order.raw_data.buyPrice);
    const itemShippingCost = _getShippingCost(internalProduct, key);
    const itemDeliveryId = _getDeliveryId(internalProduct, key);
    const phone = (order.raw_data.receiverMobile || '').replace(
      /^[+]{0,1}886/,
      '0',
    );
    const _baseData = {
      ...order,
      delivery_id: itemDeliveryId,
      erp_id: order.erp_id,
      order_id: order.order_id,
      phone: phone,
      date: date,
      provider_display: PROVIDER_ERP_ORDER_MAP[order.provider],
    };
    return [
      {
        ..._baseData,
        // row_display: "001",
        item_id: internalProduct?.id || `${order.provider}::${item.item_id}`,
        item_quantity: 1, // FIXME: ? 沒找到 quantity 欄位
        price_per_item: (order.total - itemShippingCost) / 1,
        item_total: order.total - itemShippingCost,
      },
      {
        ..._baseData,
        // row_display: "001",
        item_id: internalProduct?.delivery_type || DEFAULT_SHIPPING_ITEM_ID,
        item_quantity: 1,
        price_per_item: itemShippingCost,
        item_total: itemShippingCost,
      },
    ];
  }

  return orderResult.flat();
}

const TransformOrderFunc = {
  shopee,
  yahoo,
  yahoomall,
  rakuten,
  momomall,
  momocenter,
};

function _transformOrder(o, extra) {
  if (!TransformOrderFunc[o.provider]) {
    return [];
  }

  // 1. spread per item_ids element as per row
  // 2. add shipping_item for each row, also consider shipping config for extra data
  return TransformOrderFunc[o.provider](o, extra);
}

function isValidOrder(order) {
  return !!PROVIDER[order.provider];
}

const TransformCustomerFunc = {
  shopee: Customer.shopee,
  yahoo: Customer.yahoo,
  yahoomall: Customer.yahoomall,
  rakuten: Customer.rakuten,
  momomall: Customer.momomall,
  momocenter: Customer.momocenter,
};

function _transformCustomer(o, extra) {
  if (!TransformCustomerFunc[o.provider]) {
    return [];
  }

  try {
    return TransformCustomerFunc[o.provider](o, extra);
  } catch (err) {
    console.log('err >>>>', err);
    return [];
  }
}

function _getItemIds(order) {
  try {
    switch (order.provider) {
      case PROVIDER.momocenter: // FIXME: confirm multiple?
        return [order.raw_data.goodsCode];
      case PROVIDER.rakuten:
        return order.raw_data.orderItems.map((item) => item.baseSku);
      case PROVIDER.yahoo:
        return order.raw_data.Products.map((item) => item.Id);
      case PROVIDER.yahoomall: // FIXME: confirm multiple?
        return [
          order.raw_data.Response.Transaction.SuccessList.OrderList.Order
            .OrderProductList.Product['@Id'],
        ];
      case PROVIDER.momomall: // FIXME: confirm multiple?
        return [order.raw_data.goodsCode];
      case PROVIDER.shopee:
        return order.raw_data.item_list.map((item) => item.item_id);
      default:
        return [];
    }
  } catch (err) {
    return [];
  }
}

function normalizeItemIds(order) {
  return {
    ...order,
    provider: order.provider,
    item_ids: _getItemIds(order), // notice: order may have multiple items
  };
}

function _breakInternalGroupProductToRows(order) {
  // some internal product are group-product (ex, RXV22SVLT_FTXV22SVLT), which should break into two product in ERP
  if (order.item_id.indexOf('_') !== -1) {
    const _baseData = {
      ...order,
    };

    let internalIds = order.item_id.split('_');
    return internalIds.map((id, idx) => ({
      ..._baseData,
      item_id: id,
      item_quantity: order.item_quantity,
      price_per_item: idx === 0 ? order.price_per_item : 0,
      item_total: idx === 0 ? order.item_total : 0,
    }));
  }

  return order;
}

function _groupRowByOrderIdAndAppendRowIdx(orders) {
  if (orders.length === 0) {
    return [];
  }

  // already know order will be in order id sequence
  let results = [];
  let chunk = [];
  let currId = orders[0].id;
  for (let order of orders) {
    if (order.id === currId) {
      chunk.push(order);
    } else {
      results.push(chunk);
      chunk = [order];
      currId = order.id;
    }
  }
  results.push(chunk);

  let groupOrdersWithRowIdx = results.map((groupOrder) =>
    groupOrder.map((o, idx) => ({
      ...o,
      row_display: `0000${idx + 1}`.slice(-4),
    })),
  );
  return groupOrdersWithRowIdx.flat();
}

function transformOrderListData(orders, extra = {products: [], date: ''}) {
  let customerResult = orders.map((o) => _transformCustomer(o, extra) || []);

  let _preOrderResult = orders
    .map((o) => _transformOrder(o, extra) || [])
    .flat()
    .map(_breakInternalGroupProductToRows)
    .flat();
  let orderResult = _groupRowByOrderIdAndAppendRowIdx(_preOrderResult);

  return {orders: orderResult, customers: customerResult};
}

export {
  shopee,
  yahoo,
  yahoomall,
  rakuten,
  momomall,
  momocenter,
  transformOrderListData,
  normalizeItemIds,
  isValidOrder,
};
